<template>
  <div class="container">
      <div class="header">
          <img src="../assets/logo.png" alt="logo" height="48"/>
          <div>
              <span style="color:#999;">欢迎使用「E快讯」国际短信服务</span>
              <Button :class="{active: activeBtn === 'login'}" type="text" size="large" @click="activeBtn = 'login'">登录</Button>
              <Button :class="{active: activeBtn === 'register'}" type="text" size="large" @click="activeBtn = 'register'">注册</Button>
              <Button :class="{active: activeBtn === 'forgetPw'}" type="text" size="large" @click="activeBtn = 'forgetPw'">忘记密码</Button>
              <!-- <Button type="text" size="large">ENGLISH</Button> -->
          </div>
      </div>
      <div class="content">
          <div class="imgw">
            <img src="../assets/loginAndRegister.691b863.png" alt="logo"/>
          </div>
          <!-- 登录 -->
          <Form v-show="activeBtn === 'login'" ref="login-form" class="form" :model="loginFormObj" :rules="ruleValidateLogin">
            <h1>登录</h1>
            <FormItem prop="phoneNumber">
                <Input type="text" v-model="loginFormObj.phoneNumber" clearable placeholder="请输入手机号">
                    <Icon type="ios-person-outline" slot="prepend"></Icon>
                </Input>
            </FormItem>
            <FormItem prop="loginPw">
                <Input type="password" v-model="loginFormObj.loginPw" clearable placeholder="请输入密码">
                    <Icon type="ios-lock-outline" slot="prepend"></Icon>
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" long shape="circle" @click="login">登录</Button>
            </FormItem>
            <div class="foot">
                <div>
                    还没有账号？
                    <a @click="activeBtn = 'register'">快速注册</a>
                </div>
                <a @click="activeBtn = 'forgetPw'">忘记密码</a>
            </div>
          </Form>
          <Form v-show="activeBtn === 'register'" ref="register-form" class="form" :model="registerFormObj" :rules="ruleValidateRegister">
            <h1>欢迎注册</h1>
            <FormItem prop="nickname">
                <div>
                    <Input type="text" v-model="registerFormObj.nickname" clearable placeholder="请输入昵称（选填）"></Input>
                </div>
            </FormItem>
            <FormItem prop="phoneNumber">
                <div style="display:flex;justify-content: space-between;">
                    <Input type="text" v-model="registerFormObj.areaCode" placeholder="国际区号" style="width: 10vw;"></Input>
                    <Input type="text" v-model="registerFormObj.phoneNumber" clearable placeholder="请输入手机号" style="margin-left:10px;"></Input>
                </div>
            </FormItem>
            <FormItem prop="captcha">
                <Input type="text" v-model="registerFormObj.captcha" placeholder="请输入短信验证码">
                    <Button style="padding: 0 10px;" :disabled="countdown > 0" slot="append" @click="sendSmsCode(registerFormObj.areaCode, registerFormObj.phoneNumber)">{{countdown > 0 ? countdown + 's' : '获取验证码'}}</Button>
                </Input>
            </FormItem>
            <FormItem prop="loginPw">
                <Input type="password" v-model="registerFormObj.loginPw" clearable placeholder="请输入密码，建议至少使用两种字符组合"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" long shape="circle" @click="register">立即注册</Button>
            </FormItem>
            <div class="foot">
                <div>
                    已有账号
                    <a @click="activeBtn = 'login'">去登录</a>
                </div>
            </div>
          </Form>
          <Form v-show="activeBtn === 'forgetPw'" ref="forget-form" class="form" :model="forgetPwFormObj" :rules="ruleValidateForget">
            <h1>重置密码</h1>
            <FormItem prop="phoneNumber">
                <div>
                    <Input type="text" v-model="forgetPwFormObj.phoneNumber" clearable placeholder="请输入手机号"></Input>
                </div>
            </FormItem>
            <FormItem prop="captcha">
                <Input type="text" v-model="forgetPwFormObj.captcha" placeholder="请输入短信验证码">
                    <Button style="padding: 0 10px;" :disabled="countdown > 0" slot="append" @click="sendSmsCode(null, forgetPwFormObj.phoneNumber)">{{countdown > 0 ? countdown + 's' : '获取验证码'}}</Button>
                </Input>
            </FormItem>
            <FormItem prop="loginPw">
                <Input type="password" v-model="forgetPwFormObj.loginPw" clearable placeholder="请输入新密码"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" long shape="circle" @click="resetLoginPw">确认提交</Button>
            </FormItem>
            <div class="foot">
                <div>
                    已有账号
                    <a @click="activeBtn = 'login'">去登录</a>
                </div>
            </div>
          </Form>
      </div>
  </div>
</template>

<script>
const sha1 = require('sha1');
export default {
    name: 'Login',
    data() {
        return {
            activeBtn: 'login',
            loginFormObj: {
                phoneNumber: '',
                loginPw: ''
            },
            ruleValidateLogin: {
                phoneNumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                loginPw: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            },
            registerFormObj: {
                nickname: '',
                areaCode: '86',
                phoneNumber: '',
                captcha: '',
                loginPw: ''
            },
            ruleValidateRegister: {
                phoneNumber: [
                    { required: true, message: '请输入手机号' }
                ],
                captcha: [
                    { required: true, message: '请输入验证码' }
                ],
                loginPw: [
                    { required: true, message: '请输入密码' }
                ]
            },
            forgetPwFormObj: {
                phoneNumber: '',
                captcha: '',
                loginPw: ''
            },
            ruleValidateForget: {
                phoneNumber: [
                    { required: true, message: '请输入手机号' }
                ],
                captcha: [
                    { required: true, message: '请输入验证码' }
                ],
                loginPw: [
                    { required: true, message: '请输入密码' }
                ]
            },
            countdown: 0
        }
    },
    mounted() {
        if (this.$store.state.hasLogin) {
            this.$router.replace('/');
        } else {
            const token = localStorage.getItem('token');
            if (token) {
                this.apiV1({
                    method: 'get',
                    url: '/uinfo',
                    headers: {'Authorization': token}
                })
                .then(response => {
                    const { success, data } = response.data;
                    if (success) {
                        this.$Message.success('自动登录');
                        this.$store.commit('login', { uinfo: data, token })
                        this.$router.replace('/');
                    } else {
                        localStorage.removeItem('token');
                    }
                })
            }
        }
    },
    methods: {
        login() {
            this.$refs['login-form'].validate((valid) => {
                if (valid) {
                    this.apiV1({
                        method: 'post',
                        url: '/login',
                        data: this.loginFormObj
                    }).then(response => {
                        const { success, data } = response.data;
                        if (success) {
                            this.$store.commit('login', data);
                            localStorage.setItem('token', data.token);
                            this.$Message.success('登录成功');
                            this.$router.replace('/');
                        }
                    });
                }
            })
        },
        register() {
            if (!this.registerFormObj.areaCode) {
                this.$Message.error('请输入国际区号');
                return;
            }
            this.$refs['register-form'].validate((valid) => {
                if (valid) {
                    this.apiV1({
                        method: 'post',
                        url: '/register',
                        data: this.registerFormObj
                    }).then(response => {
                        const { success } = response.data;
                        if (success) {
                        }
                    });
                }
            })
        },
        sendSmsCode(areaCode, phoneNumber) {
            if (!phoneNumber) {
                this.$Message.error('请输入手机号');
                return;
            }
            const t = Date.now();
            const sign = sha1(areaCode + phoneNumber + t + 'zkj3219ak1m');
            this.apiV1({
                method: 'post',
                url: '/sendSmsCode',
                data: {
                    t,
                    areaCode,
                    phoneNumber,
                    sign
                }
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '验证码已发送，请注意查收'
                    });
                    this.countdown = 60;
                    const timer = setInterval(() => {
                        this.countdown--;
                        if (this.countdown === 0) {
                            clearInterval(timer);
                        }
                    }, 1000);
                }
            });
        },
        resetLoginPw() {
            this.$refs['forget-form'].validate((valid) => {
                if (valid) {
                    this.apiV1({
                        method: 'post',
                        url: '/resetLoginPw',
                        data: this.forgetPwFormObj
                    }).then(response => {
                        const { success, data } = response.data;
                        if (success) {
                            this.$store.commit('login', data);
                            localStorage.setItem('token', data.token);
                            this.$Message.success('密码已重置，自动登录');
                            this.$router.replace('/');
                        }
                    });
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    height: 100vh;
    background-color: #fff;
    .header {
        height: 64px;
        padding: 0 5.6vw 0 2.8vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .active {
            color: #2d8cf0;
        }
    }
    .content {
        margin-top: 144px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        >div.imgw {
            width:21.08vw;
            // margin-right: 15.2vw;
            >img {
                width: 100%;
            }
        }
        .form {
            width: 32vw;
            min-width: 210px;
            >h1 {
                margin-bottom: 12px;
                font-size: 3rem;
                color: #000;
                font-weight: bold;
                white-space: nowrap;
            }
            .foot {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>